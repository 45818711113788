import { createSlice } from '@reduxjs/toolkit'
import { SENSOR_DASHBOARD_PATHNAME } from '../../api/constants'

export enum NavToggleElement {
  createPDF = 'createPDF',
  downloadReport = 'downloadReport',
  startReport = 'startReport',
  genericDownloadReport = 'genericDownloadReport',
}
export interface NavToggleType {
  name: string
  pathkey: string | RegExp
  element: NavToggleElement
  elementValue?: any
  visibleAtPath: boolean
  isVisible: boolean
  requiredPermission?: any
}
export type NavToggleElements = {
  [T in keyof typeof NavToggleElement]: NavToggleType
}

export const NavToggleDefaults = {
  [NavToggleElement.startReport]: {
    name: 'Sensors Overview Temp Button',
    pathkey: SENSOR_DASHBOARD_PATHNAME,
    element: NavToggleElement.startReport,
    visibleAtPath: true,
    isVisible: false,
    elementValue: false,
  },
  [NavToggleElement.downloadReport]: {
    name: 'Sensors Overview Download Report Button',
    pathkey: SENSOR_DASHBOARD_PATHNAME,
    element: NavToggleElement.downloadReport,
    visibleAtPath: true,
    isVisible: false,
    elementValue: false,
  },
  [NavToggleElement.genericDownloadReport]: {
    name: 'Download Report Button',
    pathkey: /^\/tasks$/,
    element: NavToggleElement.downloadReport,
    visibleAtPath: true,
    isVisible: false,
    elementValue: false,
  },
  [NavToggleElement.createPDF]: {
    name: 'Sensors Overview Hide Create PDF',
    pathkey: SENSOR_DASHBOARD_PATHNAME,
    element: NavToggleElement.createPDF,
    visibleAtPath: false,
    isVisible: true,
    elementValue: false,
  },
}

type AppState = {
  sidebarShow: 'responsive' | boolean
  asideShow: boolean
  darkMode: boolean
  navToggleElements: NavToggleElements
}

const initialState: AppState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  navToggleElements: { ...NavToggleDefaults },
}

export const appkey = 'app'

export enum AppAction {
  setState = 'setState',
  toggleNavElements = 'toggleNavElements',
  setNavElementValue = 'setNavElementValue',
}

export const AppDispatches = {
  [AppAction.setState]: `${appkey}/${AppAction.setState}`,
  [AppAction.toggleNavElements]: `${appkey}/${AppAction.toggleNavElements}`,
  [AppAction.setNavElementValue]: `${appkey}/${AppAction.setNavElementValue}`,
}
// export const AppCallback = Object.fromEntries(
//   Object.entries(AppAction).map(([key, value]: [string, AppAction]) => {
//     return [key, `${appkey}/${value}`]
//   })
// )
export const appSlice = createSlice({
  name: appkey,
  initialState,
  reducers: {
    [AppAction.setState]: (state: AppState, action: any) => {
      return { ...state, ...action.payload }
    },
    [AppAction.toggleNavElements]: (state: AppState, action: any) => {
      // console.log('State, actions in toggleNavElements are: ', state, action)
      const toggleElement = NavToggleElement[action.payload.element as keyof typeof NavToggleElement]
      const currentPath = action.payload.location
      if (toggleElement && currentPath) {
        const pathKey = state.navToggleElements[toggleElement].pathkey
        const isPathKeyRegex = pathKey instanceof RegExp && pathKey.test(currentPath)
        const isPathKeyString = typeof pathKey === 'string' && currentPath.includes(state.navToggleElements[toggleElement].pathkey)
        if (isPathKeyRegex || isPathKeyString) {
          if (state.navToggleElements[toggleElement].isVisible === state.navToggleElements[toggleElement].visibleAtPath) {
            // console.log("Path didn't change, no need to reset nav toggle element: ", toggleElement, currentPath)
          } else {
            state.navToggleElements[toggleElement].isVisible = state.navToggleElements[toggleElement].visibleAtPath
          }
        } else {
          if (state.navToggleElements[toggleElement].isVisible === !state.navToggleElements[toggleElement].visibleAtPath) {
            // console.log('Path changed, but value is correct no need to reset nav toggle element: ', toggleElement, currentPath)
          } else {
            state.navToggleElements[toggleElement].isVisible = !state.navToggleElements[toggleElement].visibleAtPath
          }
        }
      }
    },
    [AppAction.setNavElementValue]: (state: AppState, action: any) => {
      const toggleElement = NavToggleElement[action.payload.element as keyof typeof NavToggleElement]
      if (toggleElement) {
        state.navToggleElements[toggleElement].elementValue = action.payload.value
      }
    },
  },
})

export const { setState, toggleNavElements } = appSlice.actions
export default appSlice.reducer
