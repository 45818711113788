import { API_URL_REFRESH_TOKEN, DISPLAY_PAGES } from '../constants'
import Cookies from 'js-cookie'
import { api } from '../api'
import { AuthContext, AuthenticationStatus, LoginEvent } from './AuthenticationContext'
import { CurrentUser } from './auth.types'
import history from '../history'
import _ from 'lodash'

export const userLogout = () => {
  Cookies.remove(LoginEvent.loginCookie)
  localStorage.removeItem(LoginEvent.logout)
  const currentPath = history.location.pathname
  const allowedPaths = ['/email-unsubscribed', '/set-new-password', '/initial-password']
  if (!allowedPaths.includes(currentPath)) {
    history.push('/login')
  }
}

export const displayPagesChanged = (currSideBar: any, respSideBar: any) => {
  if (!currSideBar !== !respSideBar) return true
  if (currSideBar.length != respSideBar.length) return true
  const respSet: any = new Set(respSideBar)
  const respEvery: boolean = currSideBar?.every((page: any) => respSet.has(page))
  if (!respEvery) return true
  const currSet: any = new Set(currSideBar)
  const currEvery: boolean = respSideBar.every((page: any) => currSet.has(page))
  if (!currEvery) return true
  return false
}

export const checkTokenBeforeAppLaunch = async () => {
  let reLogInNeeded = true
  try {
    if (Cookies.get(LoginEvent.loginCookie)) {
      const initializerLoginResponse = await api
        .noAuth()
        .url(`${API_URL_REFRESH_TOKEN}`)
        .options({ credentials: 'include', mode: 'cors', cookie: Cookies.get(LoginEvent.loginCookie) })
        .post()
        .json()
        .then(api.zjson)

      if (initializerLoginResponse && initializerLoginResponse.accessToken) {
        const {
          userId,
          userEmail,
          firstName,
          lastName,
          accessToken,
          accessTokenExpMs,
          sidebarItems,
          developerOptions,
          temperatureInC,
          permissions,
        } = initializerLoginResponse
        api.setToken(accessToken)
        Cookies.set(LoginEvent.loginCookie, AuthenticationStatus.loggedIn)
        localStorage.setItem(LoginEvent.logout, AuthenticationStatus.loggedOut + Math.random())
        reLogInNeeded = false
        const initialAuthState = {
          authState: {
            currentUser: { userId, userEmail, firstName, lastName } as CurrentUser,
            accessToken,
            currentContext: AuthContext.loggedIn,
            tokenExpiration: accessTokenExpMs,
            sidebarItems,
            displayPages: developerOptions ? [...sidebarItems, DISPLAY_PAGES.ITEM_DEVELOPER_OPTIONS] : [...sidebarItems],
            developerOptions,
            temperatureInC,
            initialized: true,
            permissions: permissions,
          },
          success: true,
        }
        console.log('[auth.checkTokenBeforeAppLaunch] auth state ', initialAuthState)
        return { ...initialAuthState }
      } else {
        Cookies.remove(LoginEvent.loginCookie)
        localStorage.removeItem(LoginEvent.logout)
        const currentPath = history.location.pathname
        const allowedPaths = ['/email-unsubscribed', '/set-new-password', '/initial-password', '/login']
        if (!allowedPaths.includes(currentPath)) {
          history.push('/login')
        }
        return { success: false }
      }
    } else {
      userLogout()
    }
  } catch (e: any) {
    return { success: false }
  }
}
